/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BootstrapTable from '@cristhiansalta/react-bootstrap-table-next/packages/react-bootstrap-table2/dist/react-bootstrap-table-next';
import '@cristhiansalta/react-bootstrap-table-next/packages/react-bootstrap-table2/dist/react-bootstrap-table2.min.css';
import TableHeader from '../Table/TableHeader/TableHeader';
import { getDashboardData } from '../../actions/visualization.action';
import './style.css';

const ReactTableContainer = () => {
  const jwt = useSelector((state) => state.session.jwt);
  const dashboardData = useSelector((state) => state.visualizations.dashboardData);
  const dispatch = useDispatch();
  const data = [];

  if (dashboardData && dashboardData.length > 0) {
    dashboardData.forEach((element) => {
      const { userData } = element.Data;
      let { granularity } = userData;
      if (Array.isArray(granularity) && granularity.length > 0) {
        granularity = granularity.join(', ');
        userData.granularity = granularity;
      }
      data.push(userData);
    });
  }

  useEffect(() => {
    dispatch(getDashboardData(jwt));
  }, []);

  // If provider is updated, this schema should be updated.
  const columns = [
    {
      dataField: 'fileName',
      text: 'File Name',
      headerClasses: 'custom-th-lg',
      // sort: true,
    },
    {
      dataField: 'description',
      text: 'Description',
      headerClasses: 'custom-th-lg',
    },
    {
      dataField: 'years',
      text: 'Years',
    },
    {
      dataField: 'granularity',
      text: 'Granularity',
    },
    {
      dataField: 'createdAt',
      text: 'Date Uploaded',
    },
  ];

  return (
    <div className="ReactTableContainer">
      <TableHeader />
      <BootstrapTable
        trStyle={{ backgroundColor: 'red' }}
        keyField="table"
        data={data}
        columns={columns}
        headerWrapperClasses="custom-thead"
        rowClasses="custom-row"
      />
      {data.length <= 0 && (
        <div className="Table-emptyState">
          <img src="/no-data-art.svg" alt="No data art" />
          <p className="Table-emptyState-message"> You have not uploaded any data yet </p>
        </div>
      )}
    </div>
  );
};

export default ReactTableContainer;
