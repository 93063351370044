import React from 'react';
import { Container } from 'react-bootstrap';
import '@cristhiansalta/react-bootstrap-table-next/packages/react-bootstrap-table2/dist/react-bootstrap-table2.min.css';
import ReactTableContainer from '../Components/ReactTableContainer';
import SectionTitle from '../Components/SectionTitle';

const DashboardDataManager = () => (
  <div className="DashboardDataManager">
    <SectionTitle
      title="Data Source Manager"
    />
    <Container>
      <ReactTableContainer />
    </Container>
  </div>
);

export default DashboardDataManager;
