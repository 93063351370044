/* eslint-disable consistent-return */
import axios from 'axios';
import { API_ROOT } from '../config/appConfig';

export const getDashboardVisualizationApi = async (jwt) => {
  try {
    const res = await axios.get(`${API_ROOT}/api/query/visualization?create=true`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      },
    });

    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.error(err);
  }
};

export const getDashboardMenuOptionsApi = async (jwt) => {
  try {
    const res = await axios.get(`${API_ROOT}/api/query/dashboard-menu-options`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      },
    });

    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.error(err);
  }
};

export const postDashboardApi = async (visualization, jwt) => {
  try {
    const res = await axios.post(
      `${API_ROOT}/api/query/query/dashboard`,
      {
        ...visualization,
      },
      {
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.error(err);
  }
};

export const postDashboardVisualizationsApi = async (visualization, jwt) => {
  try {
    const res = await axios.post(
      `${API_ROOT}/api/query/visualization`,
      {
        ...visualization,
      },
      {
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.error(err);
  }
};

export const clearDashboardCacheApi = (jwt) => {
  return axios.delete(`${API_ROOT}/api/query/dashboard/cache`, {
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  });
};

export const getDashboardShareALinkApi = async (idVisualization, jwt) => {
  try {
    const res = await axios.get(
      `${API_ROOT}/api/query/visualization/${idVisualization}/share?active=1`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.error(err);
  }
};
